import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to Calculate Yield Farming Returns`}</h1>
    <p>{`At DefiYieldCalculator.com, we build tools to help evaluate the profitability of a particular type of cryptocurrency speculation, which is known as yield farming. `}</p>
    <h3>{`How Much Can You Earn From Yield Farming?`}</h3>
    <p>{`Like anything else in the wild west world of crypto and defi, there is opportunity for both wild profit and spectacular ruin when staking cryptocurrency. `}</p>
    <p>{`As we will explore in this article, the effective rate of return depends on far more than just the advertised APR, so it's not just a case of apeing into whatever farm has the highest number on any given day. `}</p>
    <p>{`To make a good, reliable return without risking financial ruin in crypto requires you to put in an effort to understand not just the potential returns, but also the risks and downsides associated with staking and yield farming.`}</p>
    <h3>{`What is a Pool 2 in Crypto?`}</h3>
    <p>{`A pool2 is a method by which crypto projects seek to ensure their token is sufficiently liquid. By liquid, we simply mean that anyone who wants to buy or sell the token is able to do so, even if they want to buy or sell a large amount. `}</p>
    <p>{`Like any other desirable human behaviour, this is achieved via incentives. Projects will incentivise speculators to supply their token (let's call it XYZ token), plus some other token (usually ETH or some stablecoin), in a fixed proportion (usually 50/50), to an aggregated market maker such as sushi, uniswap or balancer. The incentive is paid in XYZ token.`}</p>
    <h3>{`How Are Staking Rewards Calculated?`}</h3>
    <p>{`While the maths is relatively complicated, the process is quite simple to explain. At each time step (each block), an amount of tokens are split proportionally between everyone who has supplied liquidity to the pool. `}</p>
    <p>{`So if the total pool is worth $100 and Bob has supplied $50 of that liquidity, then half of the token reward for that block is paid to Bob. If he then puts in another $50, the total pool is now worth $150, so Bob now gets two thirds of the token rewards per block. `}</p>
    <h3>{`Are Staking Rewards Paid in Crypto?`}</h3>
    <p>{`Yes - but this is not always a good thing! When trying to figure out whether or not to take this type of speculative bet, you need to think about how the price of XYZ token is likely to change between now and then you try to sell your XYZ tokens for something else. `}</p>
    <p>{`Unless XYZ token entitles you to a stream of income - a service that people are willing to pay to use - there's no particular reason why it should have any value at all. `}</p>
    <p>{`Remember - anyone can make an ERC20 token and incentivise a pool on sushiswap. The fact that XYZ tokens exist and people are seemingly buying and selling them does not offer any guarantee at all that anyone will pay anything for one in a month or a year's time. `}</p>
    <h3>{`Which Crypto Has Highest Staking Rewards?`}</h3>
    <p>{`It's not unusual to see farms offering 5, 6 or even 7 figure APRs. If that sounds too good to be true, it's because it almost certainly is. These numbers are normally a trap for suckers to jump into the pool looking for a quick buck, only for the developers/insiders to sell all of their tokens to the pool, so draining all the ETH/USD and leaving the pool value at 0. `}</p>
    <p>{`If you take one thing from this article let it be this - don't fall into the trap of thinking a massive advertised APR means you're going to make a heap of money! These rewards are paid in tokens that will almost inevitably go to 0, meaning you're almost certainly going to lose all your money.  `}</p>
    <p>{`In order to make money in crypto, like any other arena, you have to do some actual work - this means AT LEAST taking the time to understand and model the payoff of the bet you are making under the whole range of possibilities that could come to pass.`}</p>
    <h3>{`APR to APY Calculator Crypto`}</h3>
    <p>{`So far in this article I've used APR (annual percentage rate) as the basis for our discussion. However, there is a subtle difference between APR and APY (annual percentage yeild) that actually makes a large difference to the profitability of yield farming pool2s. `}</p>
    <p>{`Put simply, APR is the rate of return you'd get in a year, assuming:`}</p>
    <ul>
      <li parentName="ul">{`the price of XYZ token does not change (this is a massive assumption that is simply wrong in most cases)`}</li>
      <li parentName="ul">{`you do not compound your rewards at any point during that year.`}</li>
    </ul>
    <p>{`So, if you put $100 into a pool that pays 100% APR, then fell into a coma for a year and claimed your rewards at the end, with these assumptions you'd have $200. However, if you claimed your rewards after 6 months, you would have had $150 in the pool for the second 6 months, netting you $75 in returns over that period. Therefore, the APR is still 100%, but your APY is 125% ($125 rewards claimed over $100 initial investment).`}</p>
    <p>{`For a simple to use calculator and a more in depth explanation of this difference, check out `}<a parentName="p" {...{
        "href": "https://www.aprtoapy.com/"
      }}>{`this calculator`}</a>{`. `}</p>
    <h3>{`How Do You Calculate APY in Yield Farming?`}</h3>
    <p>{`Hopefully this illustrates that your APY when yield farming depends quite a lot on the frequency that you claim and reinvest (or sell..) your rewards.`}</p>
    <p>{`In the specific context of decentralised finance (aka defi), there's also another variable to consider, which is the cost of claiming and reinvesting those rewards. If the farm you are evaluating is on Ethereum Mainnet, the cost of claiming, rebalancing and staking your rewards might a substantial amount depending on gas fees at the time, not to mention the opportunity cost of your time spent manually clicking buttons.`}</p>
    <p>{`Also, as I've hopefully made plain at this point, the actual number of dollars (or ETH) that you get back at the end of your investment horizon depends critically on the price of XYZ token when you come to sell them. Though high APRs mapping to astronomic APYs might seem tempting to the inexperienced, the fundamentals of XYZ token are the dominant variable when determining the profitability of yeild farming XYZ token or not. `}</p>
    <h3>{`How Do You Calculate APY - Example`}</h3>
    <p>{`By this point, you might be feeling confused or overwhelmed, and I sympathise. All we want is to be told which token to buy, but now we're being asked to weigh up a bunch of variables which are hard to quantify and crunch a bunch of numbers. Can't someone do all of this work for us??`}</p>
    <p>{`Today is your lucky day frend :)`}</p>
    <p>{`I built `}<a parentName="p" {...{
        "href": "https://www.defiyieldcalculator.com/calculator/"
      }}>{`the calculator on this site`}</a>{` so that apes like you and me can play around with the variables affecting profitability and make better, more informed judgements.`}</p>
    <p>{`I also in the process of making an extensive video course where we cover the fundamentals of valuing crypto projects. Using experience I gained over 4 years studying economics and business enterprise, followed by 10 years as a professional online poker player, my goal with this course is to give you solid ground to stand on in the ever-evolving world of cryptocurrency speculation. If you want to be notified when this course is made available to the public, sign up to the newsletter.`}</p>
    <h3>{`What Is a Good Profit Percentage For Crypto?`}</h3>
    <p>{`The world of crypto is a fascinating vehicle through which to observe human behaviour. In bull markets, the madness of crowds takes over as people FOMO into whatever project shouts the loudest about all the money they're sure to make. In bear markets, market participants compete to see who can panic most and fastest. At all points, hyperbole is shared around on Crypto Twitter by those seeking attention and/or exit-liquidity.`}</p>
    <p>{`However chaotic an arena this can be, crypto is still a subset of the real world, and as such all the normal rules (if not regulations..) apply. `}</p>
    <ul>
      <li parentName="ul">{`There is still no such thing as a free lunch.`}</li>
      <li parentName="ul">{`Work is still required in order to reliably make money.`}</li>
      <li parentName="ul">{`Informed decisions based on a rational evaluation of the circumstances will still outperform those motivated purely by greed or fear. `}</li>
    </ul>
    <p>{`While everyone is seduced by the allure of quick riches, the slower path of building understanding, testing hypotheses and learning from the past drastically increases your chances of succeeding and making money over the long run.`}</p>
    <p>{`So if you're new to this space, don't rush into something and get burned. Take your time, learn the basics and resist the urge to jump into something you don't understand. `}</p>
    <h3>{`Wrapping Up`}</h3>
    <p>{`I hope this article has highlighted just how difficult it is to make predictions regarding the profitability of yield farming a particular token. I also hope that you've taken the time to test out a best and worst case scenario on our calculator, and that your ideas about the world bear a slightly closer resemblance to reality. `}</p>
    <p>{`If you've enjoyed this article, it would be a huge help for me if you could share it with a friend.`}</p>
    <p>{`If you want to dive further into the art and science of valuing crypto projects, sign up to the mailing list so you can be the first to know when new content is available.`}</p>
    <p>{`GL out there!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      